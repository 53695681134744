/* eslint-disable no-undef */
import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isApiKeyLoading: false,
  errorMsg: "",
  apiKeys: {},
};

const apiKeySlice = createSlice({
  name: "apiKey",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Get api keys list -- */
      .addCase(getApiKeys.pending, (state) => {
        state.isApiKeyLoading = true;
        state.errorMsg = "";
      })
      .addCase(getApiKeys.fulfilled, (state, action) => {
        state.isApiKeyLoading = false;
        state.errorMsg = "";
        state.apiKeys = action.payload;
      })
      .addCase(getApiKeys.rejected, (state, action) => {
        state.isApiKeyLoading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- generate new api key -- */
      .addCase(generateApiKey.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(generateApiKey.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(generateApiKey.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- update api key status -- */
      .addCase(updateApiKeyStatus.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateApiKeyStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateApiKeyStatus.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- delete api key  -- */
      .addCase(deleteApiKey.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(deleteApiKey.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(deleteApiKey.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

export const getApiKeys = createAsyncThunk(
  "apiKey/get_api_keys",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey]) {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/api-keys/?limit=20&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const generateApiKey = createAsyncThunk(
  "apiKey/generate_key",
  async (_, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/api-keys/`;

    try {
      const response = await axios.post(url, null, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateApiKeyStatus = createAsyncThunk(
  "apiKey/update_api_key_status",
  async ({ data, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/api-keys/${id}/`;

    try {
      const response = await axios.patch(url, data, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const deleteApiKey = createAsyncThunk(
  "apiKey/delete_api_key",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/api-keys/${id}/`;

    try {
      const response = await axios.delete(url, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = apiKeySlice.actions;
export default apiKeySlice.reducer;

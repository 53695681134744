import React from "react";
import Button from "./common/button/Button";
import theme from "./themeConfig";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by error boundary:", error, errorInfo);
  }

  handleGoBack = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className=" flex justify-center h-screen " data-testid="error">
          <div className=" mx-auto mt-24">
            <h1 className=" text-2xl mb-2">
              An Error encountered while processing.
            </h1>
            <Button
              variant="contained"
              label="Go back"
              onClick={this.handleGoBack}
              sx={{
                backgroundColor: theme.palette.primary.main,
                textTransform: "unset",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px",
                },
              }}
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

function AppErrorBoundary({ children }) {
  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export default AppErrorBoundary;

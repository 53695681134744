import { lazy } from "react";
import WithAuthRedirect from "../hocs/withAuthRedirect";
import WithoutAuthRedirect from "../hocs/WithoutAuthRedirect";

const Signup = lazy(() => import("../features/sign-up/Signup"));
const SignUpEmailOtpVerification = lazy(
  () => import("../features/sign-up/SignUpEmailOtpVerification"),
);
const SuccessConfirmation = lazy(
  () => import("../features/sign-up/SuccessConfirmation"),
);
const Login = lazy(() => import("../features/login/Login"));

const LoginOtpVerification = lazy(
  () => import("../features/login/LoginOtpVerification"),
);
const ResetPassword = lazy(
  () => import("../features/reset-password/ResetPassword"),
);
const ResetPasswordOtpVerification = lazy(
  () => import("../features/reset-password/ResetPasswordOtpVerification"),
);
const NewPassword = lazy(
  () => import("../features/reset-password/NewPassword"),
);
const Success = lazy(() => import("../features/payment/Success"));
const Modal = lazy(
  () => import("../common/otpnotificationmodal/OTPNotificationModal"),
);

const Error = lazy(() => import("../pages/error/Error"));
const PasswordSuccess = lazy(
  () => import("../features/reset-password/PasswordSuccess"),
);

const StepperComponent = lazy(
  () => import("../features/user-onboarding/StepperComponent"),
);

const SignUp2FA = lazy(() => import("../features/sign-up/SignUp2FA"));
const CompanyData = lazy(() => import("../features/company-data/index"));
const PageNotFound = lazy(() => import("../pages/404/PageNotFound"));
const UserOnboardingAssetQuestion = lazy(
  () => import("../pages/user-onboarding-landing/UserOnboardingAssetQuestion"),
);
const OnboardingFinal = lazy(
  () => import("../features/user-onboarding/OnboardingFinal/OnboardingFinal"),
);
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const OnboardingSteps = lazy(
  () => import("../features/onboarding-steps/OnboardingSteps"),
);
const RiskStatusOverview = lazy(
  () => import("../features/risk-status/RiskStatusOverview"),
);
const RiskStatus = lazy(
  () => import("../features/risk-status/RiskStatus/RiskStatus"),
);
const MeasuresOverview = lazy(
  () => import("../features/risk-status/MeasuresOverview/MeasuresOverview"),
);
const ImplementMeasures = lazy(
  () => import("../features/risk-status/MeasuresOverview/ImplementMeasures"),
);
const PricingHome = lazy(() => import("../features/payment/PricingHome"));
const SubscriptionPlan = lazy(
  () => import("../features/payment/SubscriptionPlan"),
);
const FreeTrialEnd = lazy(() => import("../features/payment/FreeTrialEnd"));
const AboutCompany = lazy(
  () => import("../features/user-onboarding/AboutCompany"),
);
const SearchIndustry = lazy(
  () => import("../features/user-onboarding/SearchIndustry"),
);
const BusinessProcess = lazy(
  () => import("../features/user-onboarding/BusinessProcess/BusinessProcess"),
);
const BusinessRisk = lazy(
  () => import("../features/user-onboarding/BusinessProcess/BusinessRisk"),
);
const BusinessRiskCriteria = lazy(
  () =>
    import("../features/user-onboarding/BusinessProcess/BusinessRiskCriteria"),
);
const ComplainceOnboarding = lazy(
  () => import("../features/user-onboarding/ComplainceOnboarding"),
);
const Top3Process = lazy(
  () => import("../features/user-onboarding/Top3Process"),
);

const routes = [
  {
    path: "/signup",
    element: <WithoutAuthRedirect element={<Signup />} />,
    navbar: false,
  },
  {
    path: "/login",
    element: <WithoutAuthRedirect element={<Login />} />,
    navbar: false,
  },
  {
    path: "/confirm-email-otp",
    element: <WithoutAuthRedirect element={<SignUpEmailOtpVerification />} />,
    navbar: false,
  },
  {
    path: "/signup-2FA",
    element: <WithoutAuthRedirect element={<SignUp2FA />} />,
    navbar: false,
  },
  {
    path: "/signup-confirmation",
    element: <WithAuthRedirect element={<SuccessConfirmation />} />,
    navbar: false,
  },
  {
    path: "/confirm-password-otp",
    element: <WithoutAuthRedirect element={<LoginOtpVerification />} />,
    navbar: false,
  },
  {
    path: "/reset-password",
    element: <WithoutAuthRedirect element={<ResetPassword />} />,
    navbar: false,
  },
  {
    path: "/confirm-reset-password-email",
    element: <WithoutAuthRedirect element={<ResetPasswordOtpVerification />} />,
    navbar: false,
  },
  {
    path: "/change-password",
    element: <WithoutAuthRedirect element={<NewPassword />} />,
    navbar: false,
  },
  {
    path: "/password-success",
    element: <WithoutAuthRedirect element={<PasswordSuccess />} />,
    navbar: false,
  },
  {
    path: "/choose-number-of-employees",
    element: <WithAuthRedirect element={<PricingHome />} />,
    navbar: true,
  },
  {
    path: "/choose-your-plan",
    element: <WithAuthRedirect element={<SubscriptionPlan />} />,
    navbar: true,
  },
  {
    path: "/free-trial-end",
    element: <WithAuthRedirect element={<FreeTrialEnd />} />,
    navbar: true,
  },
  {
    path: "/success",
    element: <WithAuthRedirect element={<Success />} />,
    navbar: false,
  },
  {
    path: "/modal",
    element: <WithAuthRedirect element={<Modal />} />,
    navbar: false,
  },
  {
    path: "/error",
    element: <WithoutAuthRedirect element={<Error />} />,
    navbar: false,
  },
  {
    path: "/dashboard/*",
    element: <WithAuthRedirect element={<Dashboard />} />,
    navbar: false,
  },
  {
    path: "/user-onboarding-process/*",
    element: <WithAuthRedirect element={<StepperComponent />} />,
    navbar: true,
  },
  {
    path: "/user-onboarding-process/company",
    element: <WithAuthRedirect element={<AboutCompany />} />,
    navbar: true,
  },
  {
    path: "/user-onboarding-process/industry",
    element: <WithAuthRedirect element={<SearchIndustry />} />,
    navbar: true,
  },
  {
    path: "/user-onboarding-process/process",
    element: <WithAuthRedirect element={<BusinessProcess />} />,
    navbar: true,
  },
  {
    path: "/user-onboarding-process/risk",
    element: <WithAuthRedirect element={<BusinessRisk />} />,
    navbar: true,
  },
  {
    path: "/user-onboarding-process/criteria",
    element: <WithAuthRedirect element={<BusinessRiskCriteria />} />,
    navbar: true,
  },
  {
    path: "/user-onboarding-process/complaince",
    element: <WithAuthRedirect element={<ComplainceOnboarding />} />,
    navbar: true,
  },
  {
    path: "/user-onboarding-process/critical",
    element: <WithAuthRedirect element={<Top3Process />} />,
    navbar: true,
  },
  {
    path: "/user-onboarding-risk-categorization",
    element: <WithAuthRedirect element={<OnboardingFinal />} />,
    navbar: true,
  },
  {
    path: "/user-onboarding-assets-questions",
    element: <WithAuthRedirect element={<UserOnboardingAssetQuestion />} />,
    navbar: false,
  },
  {
    path: "/company-data",
    element: <WithAuthRedirect element={<CompanyData />} />,
    navbar: false,
  },
  {
    path: "/onboarding-steps",
    element: <WithAuthRedirect element={<OnboardingSteps />} />,
    navbar: false,
  },
  {
    path: "/onboarding-risk-status-overview",
    element: <WithAuthRedirect element={<RiskStatusOverview />} />,
    navbar: false,
  },
  {
    path: "/onboarding-risk-status",
    element: <WithAuthRedirect element={<RiskStatus />} />,
    navbar: false,
  },
  {
    path: "/onboarding-measures-overview",
    element: <WithAuthRedirect element={<MeasuresOverview />} />,
    navbar: false,
  },
  {
    path: "/onboarding-measures-overview/:level",
    element: <WithAuthRedirect element={<ImplementMeasures />} />,
    navbar: false,
  },
  {
    path: "*",
    element: <PageNotFound />,
    navbar: true,
  },
];

export default routes;

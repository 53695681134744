import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import Loader from "../common/loader/Loader";
import { getCompanyPreviousDetails } from "../features/user-onboarding/userOnboardingSlice";
import { routeToAllowedPath } from "../utils/functions";

const WithoutAuthRedirect = ({ element }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const isReloading = useSelector((state) => state.login.isReloading);
  const companyDetails = useSelector(
    (state) => state.onboarding.companyDetails,
  );
  const currentCompany = useSelector((state) => state.common.currentCompany);
  const { userDetails } = useSelector((state) => state.login);

  useEffect(() => {
    if (isLoggedIn && Object.keys(userDetails || {}).length) {
      routeToAllowedPath(
        location.pathname,
        userDetails,
        companyDetails,
        currentCompany,
        navigate,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, companyDetails, userDetails]);

  // If loading, you can render a loading spinner or message
  if (isReloading) {
    return <Loader />;
  }

  // If logged in, redirect to dashboard - TODO: remove it later if no ripple effects are found
  //   if (isLoggedIn) {
  //     return <Navigate to="/dashboard/home" replace />;
  //   }

  // If not logged in, render the component
  return element;
};

export default WithoutAuthRedirect;

/* eslint-disable no-undef */
import axios from "../../utils/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  companyDetails: null,
  industryList: {},
  selectedIndustryID: "",
  businessRisk: {},
  businessProcess: {},
  loading: false,
  updateRatingLoading: false,
  errorMsg: "",
  processRating: {},
  onboardingCompanyInfo: {},
  complainceList: null,
};

/**
 * Redux slice for onboarding state management.
 */
const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setSelectedIndustryId: (state, action) => {
      state.selectedIndustryID = action.payload;
    },
    setCompany: (state, action) => {
      state.companyDetails = action.payload;
    },
    resetState() {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Get company prvious info if exist -- */

      .addCase(getCompanyPreviousDetails.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getCompanyPreviousDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.companyDetails = action.payload.company;
      })
      .addCase(getCompanyPreviousDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get company data -- */

      .addCase(getCompanyDetails.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";

        action.payload.criticalProcesses.sort(
          (a, b) => b.isCritical - a.isCritical,
        );

        state.onboardingCompanyInfo = action.payload;
      })
      .addCase(getCompanyDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get industries -- */

      .addCase(getIndustries.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getIndustries.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.industryList = action.payload;
      })
      .addCase(getIndustries.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Patch request while saving/updating steps from 2-4-- */
      .addCase(saveOnboardingData.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(saveOnboardingData.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(saveOnboardingData.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get request for Business Risk -- */

      .addCase(getBusinessRisk.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getBusinessRisk.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.businessRisk = action.payload;
      })
      .addCase(getBusinessRisk.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Delete request for Business Risk -- */

      .addCase(deleteBusinessRisk.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(deleteBusinessRisk.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(deleteBusinessRisk.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get request for Business Process -- */

      .addCase(getBusinessProcess.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getBusinessProcess.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.businessProcess = action.payload;
      })
      .addCase(getBusinessProcess.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get request for Business Process Rating -- */

      .addCase(getProcessRating.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getProcessRating.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.processRating = action.payload;
      })
      .addCase(getProcessRating.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Patch request for saving risk impact rating -- */
      .addCase(updateProcessRating.pending, (state) => {
        state.updateRatingLoading = true;
        state.errorMsg = "";
      })
      .addCase(updateProcessRating.fulfilled, (state, action) => {
        state.updateRatingLoading = false;
        state.errorMsg = "";
      })
      .addCase(updateProcessRating.rejected, (state, action) => {
        state.updateRatingLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get Complaince List -- */
      .addCase(getComplainceList.pending, (state) => {
        state.errorMsg = "";
      })
      .addCase(getComplainceList.fulfilled, (state, action) => {
        state.errorMsg = "";
        state.complainceList = action.payload.results;
      })
      .addCase(getComplainceList.rejected, (state, action) => {
        state.errorMsg = action.payload.error;
      })

      /* --- Patch request for saving critical business process CIA rating -- */
      .addCase(updateCriticalProcessCIA.pending, (state) => {
        state.updateRatingLoading = true;
        state.errorMsg = "";
      })
      .addCase(updateCriticalProcessCIA.fulfilled, (state, action) => {
        state.updateRatingLoading = false;
        state.errorMsg = "";
      })
      .addCase(updateCriticalProcessCIA.rejected, (state, action) => {
        state.updateRatingLoading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* Company data */
export const getCompanyPreviousDetails = createAsyncThunk(
  "onboading/get_companyPreviousDetails",
  async (_, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/me/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Company details */
export const getCompanyDetails = createAsyncThunk(
  "onboading/get_company_details",
  async (companyId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company/${companyId}/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Base Onboarding for all steps */
export const saveOnboardingData = createAsyncThunk(
  "onboading/post",
  async (onboardingData, { rejectWithValue }) => {
    const { id } = onboardingData;

    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company/${id}/`,
        onboardingData.data,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Get industries */
export const getIndustries = createAsyncThunk(
  "onboading/get_Industries",
  async ({ query, nextUrl } = {}, { rejectWithValue }) => {
    try {
      let url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/industries/?limit=20`;
      if (query) {
        url = `${url}&search=${encodeURIComponent(query)}`;
      }

      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Get business risk */
export const getBusinessRisk = createAsyncThunk(
  "onboading/get_BusinessRisk",
  async ({ companyId, nextUrl }, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company/${companyId}/evaluation-criteria/?limit=20`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Delete business risk */
export const deleteBusinessRisk = createAsyncThunk(
  "onboading/deleteBusinessRisk",
  async (businessRiskId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/evaluation-criteria/${businessRiskId}/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Get business process */
export const getBusinessProcess = createAsyncThunk(
  "onboading/get_business_process",
  async ({ companyId, nextUrl }, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company/${companyId}/business-processes/?limit=20`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Get business process rating */
export const getProcessRating = createAsyncThunk(
  "onboading/get_process_rating",
  async ({ companyId, nextUrl }, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company/${companyId}/process-rating/?limit=50`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Update business process rating */
export const updateProcessRating = createAsyncThunk(
  "onboading/update_process_rating",
  async ({ riskId, riskData }, { rejectWithValue }) => {
    const language = localStorage.getItem("i18nextLng") || "de";
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/evaluation-criteria/${riskId}/`,
        riskData,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Get Complaince */
export const getComplainceList = createAsyncThunk(
  "onboading/getComplainceList",
  async (_, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const url = `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/compliance/?limit=50`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* Update critical process CIA  */
export const updateCriticalProcessCIA = createAsyncThunk(
  "onboading/updateCriticalProcessCIA",
  async (processData, { rejectWithValue }) => {
    const { id: processId, ...restData } = processData;

    const language = localStorage.getItem("i18nextLng") || "de";
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/critical-processes/${processId}/`,
        restData,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const {
  setSearchString,
  setSelectedIndustryId,
  resetState,
  setCompany,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;

import { Link } from "@mui/material";
import {
  BEFORE_EVALUATION_TABS_ENABLED,
  DASHBOARD_MENU_ITEMS,
  INITIAL_CONSULTANT_TABS_ENABLED,
  INITIAL_DASHBOARD_TABS_ENABLED,
  MAP_NUM_DAYS,
  USER_ROLES,
  WORKING_DASHBOARD_TABS,
} from "./constant";

/**
 * Sanitizes the input string by removing certain special characters.
 * If a custom regular expression is provided, it uses that for sanitization.
 *
 * @param {string} input - The input string to sanitize.
 * @param {RegExp} [customRegex] - Optional custom regular expression for sanitization.
 * @returns {string} - The sanitized input string.
 */
export const sanitizeInput = (input, customRegex) => {
  if (input) {
    // removes &, >, >, ", ', `, =, /"
    const sanitizeRegex = customRegex || /[&<>"'`=/]/g;
    const filteredInput = input.replace(sanitizeRegex, "");
    return filteredInput;
  }
  return input;
};

/**
 * Extracts the file name from a given file path.
 *
 * @param {string} fileName - The full file path.
 * @returns {string|null} - The extracted file name or null if the input is invalid.
 */
export const getFileName = (fileName) => {
  if (fileName) {
    const fileArr = fileName.split("/");
    return fileArr[fileArr.length - 1];
  }
  return null;
};

/**
 * Constructs the full name from the provided first name and last name.
 *
 * @param {Object} userObject - The paginated data object.
 * @param {string} userObject.firstName -The first name.
 * @param {string} userObject.lastName - The last name.
 * @param {string} userObject.email - email
 * @returns {string} - The full name, first name, or last name depending on the inputs.
 */
export const getFullName = (userObject) => {
  if (!userObject) {
    return "-";
  }
  const { firstName, lastName, email } = userObject;
  if (
    firstName &&
    lastName &&
    typeof firstName === "string" &&
    typeof lastName === "string"
  ) {
    return `${firstName.trim()} ${lastName.trim()}`;
  } else if (firstName && typeof firstName === "string") {
    return firstName;
  } else if (lastName && typeof lastName === "string") {
    return lastName;
  }
  return email || "-";
};

/**
 * Counts the number of words in a given sentence.
 *
 * @param {string} sentence - The sentence to count words in.
 * @returns {number} - The word count in the sentence.
 */
export const getWordCount = (sentence) => {
  if (sentence) {
    const wordsArr = sentence?.trim()?.split(" ");
    const nonEmptyWordsArr = wordsArr.filter((word) => word);
    return nonEmptyWordsArr.length;
  }
  return 0;
};

/**
 * Determines the current page number based on the pagination data.
 *
 * @param {Object} data - The paginated data object.
 * @param {string} data.next - The URL of the next page.
 * @param {string} data.previous - The URL of the previous page.
 * @returns {number} - The current page number.
 */
export const getCurrentPage = (data) => {
  let pageNumber = 1; // Consistent default value
  if (data.next) {
    const pageParamsArr = data.next.split("?page=");
    pageNumber = pageParamsArr[pageParamsArr.length - 1] - 1;
  } else if (data.previous && data.previous.includes("?page=")) {
    const pageParamsArr = data.previous.split("?page=");
    pageNumber = parseInt(pageParamsArr[pageParamsArr.length - 1]) + 1;
  } else if (data.previous) {
    pageNumber = 2;
  }
  return pageNumber;
};

/**
 * Determines the current page number based on limit and offset pagination data.
 *
 * @param {Object} data - The paginated data object.
 * @param {string} data.next - The URL of the next page.
 * @param {string} data.previous - The URL of the previous page.
 * @returns {number} - The current page number.
 */
export const getCurrentPageFromLimitOffset = (data) => {
  let pageNumber = 1; // Consistent default value
  if (data.next) {
    const queryString = data.next.split("?")[1];
    const params = new URLSearchParams(queryString);
    const limit = parseInt(params.get("limit"));
    const offset = parseInt(params.get("offset"));
    pageNumber = Math.floor(offset / limit);
  } else if (data.previous) {
    const queryString = data.previous.split("?")[1];
    const params = new URLSearchParams(queryString);
    const limit = parseInt(params.get("limit"));
    const offset = parseInt(params.get("offset"));
    if (limit && offset) {
      pageNumber = Math.floor(offset / limit) + 1;
    } else {
      pageNumber = 2;
    }
  }
  return pageNumber;
};

// Helper function to format file size in bytes
export const formatBytes = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
  return Math.round(bytes / Math.pow(k, i), 2) + " " + sizes[i];
};

export const parseTextWithLinks = (text) => {
  const urlPattern = /(http?s?:\/\/[^\s]+)/g;
  const parts = text.split(urlPattern);

  return parts.map((part, index) => {
    if (urlPattern.test(part)) {
      return (
        <Link key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </Link>
      );
    }
    return part;
  });
};

// following are the utility functions for task frequency modal
export const getDailyText = (t, repeatInterval, startDate, endText) => {
  const repeatText = t("dashboard.tasks.frequencyModal.repeat_text_daily")
    .replace("${date}", startDate)
    .replace("${endText}", endText);
  if (repeatInterval === 1) {
    return repeatText.replace(
      "${day}",
      t("dashboard.tasks.frequencyModal.day"),
    );
  }
  return repeatText.replace(
    "${day}",
    `${repeatInterval} ${t("dashboard.tasks.frequencyModal.days")}`,
  );
};

export const getWeeklyText = (t, repeatInterval, startDate, endText, days) => {
  const selectedDays = days.map((day) =>
    t(`commonPlaceholder.daysOfWeek.${day}`),
  );
  let repeatText = t("dashboard.tasks.frequencyModal.repeat_text_weekly")
    .replace("${date}", startDate)
    .replace("${endText}", endText);

  if (repeatInterval === 1) {
    repeatText = repeatText.replace("${weeks}", "");
  } else {
    repeatText = repeatText.replace(
      "${weeks}",
      `${repeatInterval} ${t("dashboard.tasks.frequencyModal.weeks_on")}`,
    );
  }

  if (selectedDays.length > 2) {
    const lastDay = selectedDays[selectedDays.length - 1];
    const days = `${selectedDays.slice(0, -1).join(", ")}, ${t("commonPlaceholder.and")} ${lastDay}`;
    return repeatText.replace("${days}", days);
  } else {
    const days = selectedDays.join(` ${t("commonPlaceholder.and")} `);
    return repeatText.replace("${days}", days).replace("${endText}", endText);
  }
};

export const getMonthlyText = (
  t,
  repeatInterval,
  startDate,
  endText,
  weeklyRepeatNumber,
  weeklyRepeatDay,
  monthDays,
  monthlyOption,
) => {
  const sortedMonthDays = monthDays.sort((a, b) => {
    // If both numbers are non-negative or both are negative, sort normally
    if (a >= 0 && b >= 0) return a - b;
    if (a < 0 && b < 0) return a - b; // Preserve order for negatives at the end
    // If one is negative and the other is non-negative, the non-negative should come first
    return a >= 0 ? -1 : 1;
  });
  const selectedDays = sortedMonthDays.map((day) =>
    day < 0
      ? t(`dashboard.tasks.frequencyModal.dayNumber.${MAP_NUM_DAYS[day]}`)
      : day,
  );

  const onTheLabel = t(
    "dashboard.tasks.frequencyModal.weekly_repeat_text",
  ).toLowerCase();

  let repeatText = t("dashboard.tasks.frequencyModal.repeat_text_monthly")
    .replace("${date}", startDate)
    .replace("${endText}", endText);

  if (repeatInterval === 1) {
    repeatText = repeatText.replace(
      "${months}",
      `${t("dashboard.tasks.frequencyModal.month")},`,
    );
  } else {
    repeatText = repeatText.replace(
      "${months}",
      `${repeatInterval} ${t("dashboard.tasks.frequencyModal.months")},`,
    );
  }

  if (!monthlyOption) {
    repeatText = repeatText.replace("${days}", "");
  }

  if (monthlyOption === "each" && selectedDays.length > 2) {
    const lastDay = selectedDays[selectedDays.length - 1];
    const days = `${selectedDays.slice(0, -1).join(", ")}, ${t("commonPlaceholder.and")} ${lastDay}`;
    repeatText = repeatText
      .replace("${days}", ` ${onTheLabel} ${days},`)
      .toLowerCase();
  } else if (monthlyOption === "each" && selectedDays.length > 0) {
    const days = selectedDays.join(` ${t("commonPlaceholder.and")} `);
    repeatText = repeatText
      .replace("${days}", ` ${onTheLabel} ${days},`)
      .toLowerCase();
  } else if (monthlyOption === "each") {
    repeatText = repeatText.replace("${days}", "");
  }

  if (monthlyOption === "on" && weeklyRepeatNumber && weeklyRepeatDay) {
    const weeklyRepeatNumberText = t(
      `dashboard.tasks.frequencyModal.dayNumber.${MAP_NUM_DAYS[weeklyRepeatNumber]}`,
    ).toLowerCase();
    const weeklyRepeatDayText = t(
      `commonPlaceholder.daysOfWeek.${weeklyRepeatDay}`,
    );
    repeatText = repeatText.replace(
      "${days}",
      ` ${onTheLabel} ${weeklyRepeatNumberText} ${weeklyRepeatDayText},`,
    );
  } else if (monthlyOption === "on") {
    repeatText = repeatText.replace("${days}", "");
  }

  return repeatText;
};

export const getYearlyText = (
  t,
  repeatInterval,
  startDate,
  endText,
  weeklyRepeatNumber,
  weeklyRepeatDay,
  months,
  monthlyOption,
) => {
  const selectedMonths = months.map((month) =>
    t(`commonPlaceholder.months_full.${month}`),
  );

  const eachLabel = t("dashboard.tasks.frequencyModal.each").toLowerCase();
  const onTheLabel = t(
    "dashboard.tasks.frequencyModal.weekly_repeat_text",
  ).toLowerCase();

  let repeatText = t("dashboard.tasks.frequencyModal.repeat_text_yearly")
    .replace("${date}", startDate)
    .replace("${endText}", endText);

  if (repeatInterval === 1) {
    repeatText = repeatText.replace(
      "${years}",
      `${t("dashboard.tasks.frequencyModal.year")},`,
    );
  } else {
    repeatText = repeatText.replace(
      "${years}",
      `${repeatInterval} ${t("dashboard.tasks.frequencyModal.years")},`,
    );
  }

  if (selectedMonths.length > 2) {
    const lastMonth = selectedMonths[selectedMonths.length - 1];
    const months = `${selectedMonths.slice(0, -1).join(", ")}, ${t("commonPlaceholder.and")} ${lastMonth}`;
    repeatText = repeatText.replace(
      "${selectedMonths}",
      ` ${eachLabel} ${months},`,
    );
  } else if (selectedMonths.length > 0) {
    const months = selectedMonths.join(` ${t("commonPlaceholder.and")} `);
    repeatText = repeatText.replace(
      "${selectedMonths}",
      ` ${eachLabel} ${months},`,
    );
  } else {
    repeatText = repeatText.replace("${selectedMonths}", "");
  }

  if (monthlyOption !== "on" || !weeklyRepeatNumber || !weeklyRepeatDay) {
    repeatText = repeatText.replace("${days}", "");
  }

  if (monthlyOption === "on" && weeklyRepeatNumber && weeklyRepeatDay) {
    const weeklyRepeatNumberText = t(
      `dashboard.tasks.frequencyModal.dayNumber.${MAP_NUM_DAYS[weeklyRepeatNumber]}`,
    ).toLowerCase();
    const weeklyRepeatDayText = t(
      `commonPlaceholder.daysOfWeek.${weeklyRepeatDay}`,
    );
    repeatText = repeatText.replace(
      "${days}",
      ` ${onTheLabel} ${weeklyRepeatNumberText} ${weeklyRepeatDayText},`,
    );
  }

  return repeatText;
};

export const generateRandomEmail = () => {
  const domains = ["example.com", "test.com", "demo.com"];
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  const usernameLength = Math.floor(Math.random() * 10) + 5; // Random username length between 5 and 15
  let username = "";

  for (let i = 0; i < usernameLength; i++) {
    username += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  const domain = domains[Math.floor(Math.random() * domains.length)];
  return `${username}@${domain}`;
};

export const checkIsTabEnabled = (
  tab,
  userDetails,
  companyDetails,
  currentCompany,
) => {
  let isTabEnabled = false;
  if (userDetails.role !== USER_ROLES.CONSULTANT) {
    if (tab === "companies") {
      isTabEnabled = false;
    }
    if (companyDetails?.inConsultation) {
      if (!companyDetails.isEvaluated) {
        isTabEnabled = BEFORE_EVALUATION_TABS_ENABLED.includes(tab);
      } else if (companyDetails.isEvaluated && !companyDetails?.subscription) {
        isTabEnabled = INITIAL_CONSULTANT_TABS_ENABLED.includes(tab);
      } else if (companyDetails?.isEvaluated && companyDetails?.subscription) {
        isTabEnabled = WORKING_DASHBOARD_TABS.includes(tab);
      }
    } else if (!companyDetails?.subscription) {
      isTabEnabled = false;
    } else if (companyDetails?.isEvaluated) {
      isTabEnabled = !companyDetails?.isComplete
        ? INITIAL_CONSULTANT_TABS_ENABLED.includes(tab)
        : WORKING_DASHBOARD_TABS.includes(tab);
    } else {
      isTabEnabled = !companyDetails?.isComplete
        ? BEFORE_EVALUATION_TABS_ENABLED.includes(tab)
        : INITIAL_DASHBOARD_TABS_ENABLED.includes(tab);
    }
  } else {
    if (tab === "companies" || tab === "settings") {
      isTabEnabled = true;
    } else if (!currentCompany) {
      isTabEnabled = false;
    } else if (
      !currentCompany.inConsultation &&
      !currentCompany?.subscription
    ) {
      isTabEnabled = false;
    } else if (!currentCompany.isEvaluated) {
      isTabEnabled = !currentCompany.isComplete
        ? BEFORE_EVALUATION_TABS_ENABLED.includes(tab)
        : INITIAL_DASHBOARD_TABS_ENABLED.includes(tab);
    } else if (currentCompany.isEvaluated && !currentCompany?.subscription) {
      isTabEnabled = INITIAL_CONSULTANT_TABS_ENABLED.includes(tab);
    } else if (currentCompany?.isEvaluated && currentCompany?.subscription) {
      isTabEnabled = !currentCompany.isComplete
        ? INITIAL_CONSULTANT_TABS_ENABLED.includes(tab)
        : WORKING_DASHBOARD_TABS.includes(tab);
    }
  }
  return isTabEnabled;
};

export const routeToAllowedPath = (
  pathname,
  userDetails,
  companyDetails,
  currentCompany,
  navigate,
  consultantCompanyLoading,
) => {
  const isAdmin = userDetails.role === USER_ROLES.ADMIN;
  const ADMIN_CONSULTANTION_PATHS = [
    "choose-number-of-employees",
    "choose-your-plan",
  ];
  const checkisPathEnabled = (tabsArray, isAdminConsultation) => {
    const getTabObj = (tab) => {
      return DASHBOARD_MENU_ITEMS.find((currentTab) => currentTab.to === tab);
    };
    if (isAdminConsultation) {
      const isEnabled = ADMIN_CONSULTANTION_PATHS.find((tab) =>
        pathname.includes(tab),
      );
      if (isEnabled) {
        return true;
      }
    }
    return tabsArray.find(
      (tab) =>
        pathname.includes(`dashboard/${tab}`) &&
        (getTabObj(tab)?.isAdmin ? isAdmin : true),
    );
  };

  if (userDetails.role !== USER_ROLES.CONSULTANT) {
    if (Object.keys(companyDetails || {}).length) {
      // user invited by consultant
      if (companyDetails?.inConsultation) {
        if (!companyDetails.isEvaluated) {
          const isPathEnabled = checkisPathEnabled(
            BEFORE_EVALUATION_TABS_ENABLED,
            isAdmin,
          );
          if (!isPathEnabled) {
            navigate(`/dashboard/${BEFORE_EVALUATION_TABS_ENABLED[0]}`);
          }
        } else if (
          companyDetails.isEvaluated &&
          !companyDetails?.subscription
        ) {
          const isPathEnabled = checkisPathEnabled(
            INITIAL_CONSULTANT_TABS_ENABLED,
            isAdmin,
          );
          if (!isPathEnabled) {
            navigate(`/dashboard/${INITIAL_CONSULTANT_TABS_ENABLED[0]}`);
          }
        } else if (
          companyDetails?.isEvaluated &&
          companyDetails?.subscription
        ) {
          const isPathEnabled = checkisPathEnabled(
            WORKING_DASHBOARD_TABS,
            isAdmin,
          );
          if (!isPathEnabled) {
            navigate(`/dashboard/${WORKING_DASHBOARD_TABS[0]}`);
          }
        }
      } else if (!companyDetails?.subscription) {
        // normal user with no subscription
        // check if query params contain ?payment=success if yes then redirect to /payment-success
        if (userDetails.role === USER_ROLES.USER) {
          navigate("/free-trial-end");
        } else {
          const urlParams = new URLSearchParams(window.location.search);
          const paymentSuccess = urlParams.get("payment");
          if (paymentSuccess === "success") {
            navigate("/user-onboarding-process?payment=success");
          } else {
            navigate("/choose-number-of-employees");
          }
        }
      } else if (!companyDetails?.isComplete) {
        // user onboarding process not complete
        if (userDetails.role === USER_ROLES.ADMIN) {
          if (!pathname.startsWith("/user-onboarding-process")) {
            navigate("/user-onboarding-process");
          }
        } else {
          if (!companyDetails.isEvaluated) {
            const isPathEnabled = checkisPathEnabled(
              BEFORE_EVALUATION_TABS_ENABLED,
            );
            if (!isPathEnabled) {
              navigate(`/dashboard/${BEFORE_EVALUATION_TABS_ENABLED[0]}`);
            }
          } else {
            const isPathEnabled = checkisPathEnabled(
              INITIAL_CONSULTANT_TABS_ENABLED,
            );
            if (!isPathEnabled) {
              navigate(`/dashboard/${INITIAL_CONSULTANT_TABS_ENABLED[0]}`);
            }
          }
        }
      } else if (!companyDetails?.isEvaluated) {
        // assessment not complete yet
        const isPathEnabled = checkisPathEnabled(
          INITIAL_DASHBOARD_TABS_ENABLED,
        );
        if (!isPathEnabled) {
          navigate(`/dashboard/${INITIAL_DASHBOARD_TABS_ENABLED[0]}`);
        }
      } else if (companyDetails.isEvaluated) {
        const isPathEnabled =
          checkisPathEnabled(WORKING_DASHBOARD_TABS) &&
          !pathname.includes("companies");
        if (!isPathEnabled) {
          navigate(`/dashboard/${WORKING_DASHBOARD_TABS[0]}`);
        }
      }
    }
  } else {
    if (!location.pathname.startsWith("/dashboard")) {
      navigate("/dashboard/companies");
    } else if (!consultantCompanyLoading) {
      let isPathEnabled = true;
      if (pathname.includes("companies") || pathname.includes("settings")) {
        isPathEnabled = true;
      } else if (!currentCompany) {
        isPathEnabled = false;
      } else if (
        !currentCompany.inConsultation &&
        !currentCompany?.subscription
      ) {
        isPathEnabled = false;
      } else if (!currentCompany.isEvaluated) {
        isPathEnabled = checkisPathEnabled(
          currentCompany.isComplete
            ? INITIAL_DASHBOARD_TABS_ENABLED
            : BEFORE_EVALUATION_TABS_ENABLED,
        );
      } else if (currentCompany.isEvaluated && !currentCompany?.subscription) {
        isPathEnabled = checkisPathEnabled(INITIAL_CONSULTANT_TABS_ENABLED);
      } else if (currentCompany?.isEvaluated && currentCompany?.subscription) {
        isPathEnabled = checkisPathEnabled(
          !currentCompany.isComplete
            ? INITIAL_CONSULTANT_TABS_ENABLED
            : WORKING_DASHBOARD_TABS,
        );
      }

      if (!isPathEnabled) {
        navigate("/dashboard/companies");
      }
    }
  }
};

/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  loading: false,
  errorMsg: "",
  logs: {},
  logsLoading: false,
};

const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Get log List -- */
      .addCase(getLogs.pending, (state) => {
        state.logsLoading = true;
        state.errorMsg = "";
      })
      .addCase(getLogs.fulfilled, (state, action) => {
        state.logsLoading = false;
        state.errorMsg = "";
        state.logs = action.payload;
      })
      .addCase(getLogs.rejected, (state, action) => {
        state.logsLoading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

export const getLogs = createAsyncThunk(
  "log/get_logs",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey] && currentKey !== "date") {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/logs/?limit=20&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = logSlice.actions;
export default logSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { setCurrentCompany } from "../../store/commonSlice";
import { setCompany } from "../user-onboarding/userOnboardingSlice";

const initialState = {
  loading: false,
  errorMsg: "",
  userDetails: {},
  isLoggedIn: false,
  isReloading: true,
  userEmail: "",
  secretToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setReloading: (state, action) => {
      state.isReloading = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setSecretToken: (state, action) => {
      state.secretToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Login -- */
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Logout -- */
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.isLoggedIn = false;
        state.userDetails = {};
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.errorMsg = action?.payload?.error;
      })

      /* --- Password OTP verification -- */
      .addCase(otpVerification.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
        state.isLoggedIn = false;
      })
      .addCase(otpVerification.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.isLoggedIn = true;
      })
      .addCase(otpVerification.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;

        state.errorMsg = action.payload.error;
      })

      /* --- Get User complete information -- */

      .addCase(userCompleteDetails.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
        state.isReloading = true;
      })
      .addCase(userCompleteDetails.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.isReloading = false;
        state.loading = false;
        state.errorMsg = "";
        state.userDetails = action.payload;
      })
      .addCase(userCompleteDetails.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.isReloading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- Login -- */
export const login = createAsyncThunk(
  "auth/login",
  async (loginData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/login/`,
        loginData,
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Password OTP Confirmation -- */
export const otpVerification = createAsyncThunk(
  "loginOtpVerify/loginOpt",
  async (loginOTP, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/verify-otp/`,
        loginOTP,
        { withCredentials: true },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Logout -- */
export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch, rejectWithValue }) => {
    localStorage.removeItem("email");
    localStorage.removeItem("companyId");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/logout/`,

        { withCredentials: true },
      );

      const data = await response.data;
      dispatch(setCurrentCompany({ company: null }));

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- New API for fetching User complete details -- */
export const userCompleteDetails = createAsyncThunk(
  "auth/userCompleteDetails",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/me/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;
      dispatch(setCompany(data.company));

      return data;
    } catch (error) {
      dispatch(logout());
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const {
  resetState,
  setLoggedIn,
  setReloading,
  setUserEmail,
  setSecretToken,
} = authSlice.actions;
export default authSlice.reducer;

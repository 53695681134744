import { Button as MuiButton } from "@mui/material";

const Button = ({
  label,
  variant,
  onClick,
  startIcon,
  color,
  sx,
  children,
  ...restProps
}) => {
  return (
    <MuiButton
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      sx={sx}
      {...restProps}
    >
      {label || children}
    </MuiButton>
  );
};

export default Button;

import axios from "../../../utils/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  assetProcesses: [],
  assets: {},
  assetDetail: {},
  defaultAssets: {},
  defaultAssetsLoading: false,
  defaultSubAssets: [],
  defaultSubAssetsLoading: false,
  processSubAssetsLoading: false,
  processSubAssets: [],
  loading: false,
  isAssetsLoading: false,
  errorMsg: "",
  agentName: null,
};

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    resetState: (state, action) => {
      return initialState;
    },
    resetAssetDetail: (state, action) => {
      state.assetDetail = {};
    },
    resetAssets: (state) => {
      state.assets = {};
    },
    resetDefaultAssets: (state) => {
      state.defaultAssets = {};
      state.defaultSubAssets = [];
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- get General Business Process with Top-3 processes -- */
      .addCase(getAssetProcesses.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getAssetProcesses.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.assetProcesses = action.payload;
      })
      .addCase(getAssetProcesses.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get subassets for a business process -- */
      .addCase(getProcessSubAssets.pending, (state) => {
        state.processSubAssetsLoading = true;
        state.errorMsg = "";
      })
      .addCase(getProcessSubAssets.fulfilled, (state, action) => {
        state.processSubAssetsLoading = false;
        state.errorMsg = "";
        state.processSubAssets = action.payload?.results || [];
      })
      .addCase(getProcessSubAssets.rejected, (state, action) => {
        state.processSubAssetsLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get company assets associated with Business Process -- */
      .addCase(getAssets.pending, (state) => {
        state.isAssetsLoading = true;
        state.errorMsg = "";
      })
      .addCase(getAssets.fulfilled, (state, action) => {
        state.isAssetsLoading = false;
        state.errorMsg = "";
        state.assets = action.payload;
      })
      .addCase(getAssets.rejected, (state, action) => {
        state.isAssetsLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get company asset detail -- */
      .addCase(getAssetDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getAssetDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.assetDetail = action.payload;
      })
      .addCase(getAssetDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get get Default list of Assets -- */
      .addCase(getDefaultAssets.pending, (state) => {
        state.defaultAssetsLoading = true;
        state.errorMsg = "";
      })
      .addCase(getDefaultAssets.fulfilled, (state, action) => {
        state.defaultAssetsLoading = false;
        state.errorMsg = "";
        state.defaultAssets = action.payload;
      })
      .addCase(getDefaultAssets.rejected, (state, action) => {
        state.defaultAssetsLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get Default list of Sub Assets -- */
      .addCase(getDefaultSubAssets.pending, (state) => {
        state.defaultSubAssetsLoading = true;
        state.errorMsg = "";
        state.defaultSubAssets = [];
      })
      .addCase(getDefaultSubAssets.fulfilled, (state, action) => {
        state.defaultSubAssetsLoading = false;
        state.errorMsg = "";
        state.defaultSubAssets = action.payload;
      })
      .addCase(getDefaultSubAssets.rejected, (state, action) => {
        state.defaultSubAssetsLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- add an asset to a company -- */
      .addCase(addCompanyAsset.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(addCompanyAsset.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(addCompanyAsset.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- update an asset to a company -- */
      .addCase(updateCompanyAsset.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateCompanyAsset.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateCompanyAsset.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Delete an asset to a company -- */
      .addCase(deleteCompanyAsset.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(deleteCompanyAsset.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(deleteCompanyAsset.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* ---Add agent to asset -- */
      .addCase(addAgentToAsset.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(addAgentToAsset.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(addAgentToAsset.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* ---Agent detail -- */
      .addCase(agentDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(agentDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.agentName = action.payload.results;
      })
      .addCase(agentDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* ---delete agent -- */
      .addCase(deleteAgent.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(deleteAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(deleteAgent.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

export const getAssetProcesses = createAsyncThunk(
  "asset/get_asset_processes",
  async (_, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/asset-processes/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getProcessSubAssets = createAsyncThunk(
  "asset/get_process_sub_assets",
  async ({ processId }, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/asset-processes/${processId}/sub-assets/?limit=100`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getAssets = createAsyncThunk(
  "asset/get_assets",
  async ({ processId, nextUrl, params } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey]) {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();
      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/asset-processes/${processId}/assets/?limit=30&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getAssetDetail = createAsyncThunk(
  "asset/get_asset_detail",
  async ({ assetId }, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const url = `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company-assets/${assetId}/`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getDefaultAssets = createAsyncThunk(
  "asset/get_default_assets",
  async (nextUrl, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let url = `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/assets/?limit=20`;
      if (nextUrl) {
        url = nextUrl;
      }

      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getDefaultSubAssets = createAsyncThunk(
  "asset/get_default_sub_assets",
  async (assetId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/assets/${assetId}/sub-assets/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const addCompanyAsset = createAsyncThunk(
  "asset/add_company_asset",
  async (assetData, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/add-company-asset/`,
        assetData,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateCompanyAsset = createAsyncThunk(
  "asset/update_company_asset",
  async ({ assetId, assetData }, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company-assets/${assetId}/`,
        assetData,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const deleteCompanyAsset = createAsyncThunk(
  "asset/deleteCompanyAsset",
  async (assetId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company-assets/${assetId}/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const addAgentToAsset = createAsyncThunk(
  "asset/addAgentToAsset",
  async (agentData, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/add-wazuh-agent/`,
        agentData,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const agentDetail = createAsyncThunk(
  "asset/agentDetail",
  async (assetId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company-assets/${assetId}/wazuh-agents/?limit=100`,

        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const deleteAgent = createAsyncThunk(
  "asset/deleteAgent",
  async (agentId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/wazuh-agents/${agentId}/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState, resetAssets, resetAssetDetail, resetDefaultAssets } =
  assetSlice.actions;

export default assetSlice.reducer;
